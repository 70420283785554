@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Damion&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/freestyle-script');
@font-face {
    font-family: 'Freestyle Script';
    font-style: normal;
    font-weight: normal;
    src: local('Freestyle Script'), url('assets/fonts/Freestyle Script.woff') format('woff');
}

@font-face {
    font-family: 'Palace Script MT';
    font-style: normal;
    font-weight: normal;
    src: local('Palace Script MT'), url('assets/fonts/Palace script.woff') format('woff');
}

@font-face {
    font-family: 'Pristina';
    font-style: normal;
    font-weight: normal;
    src: local('Pristina'), url('assets/fonts/Pristina.woff') format('woff');
}

@font-face {
    font-family: 'Lucida Handwriting';
    font-display: swap;
    font-style: italic;
    font-weight: normal;
    src: url('assets/fonts/LucidaHandwritingStd.woff2') format('woff2'),
    url('assets/fonts/LucidaHandwritingStd.woff') format('woff');
}
